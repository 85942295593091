    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
    
    @font-face {
        font-family: "Montserrat";
        font-weight: normal;
        font-style: normal;
        font-optical-sizing: auto;
    }
    
    body {
        margin: 0;
        font-family: 'Montserrat', sans-serif !important;
        -webkit-font-smoothing: antialiased;
        background: #fff !important;
        -moz-osx-font-smoothing: grayscale;
    }

    h1, h2, h3, h4, h5, h6, p, a, span, label, button, li, pre, input, textarea, th, td, div {
    font-family: 'Montserrat', sans-serif !important;
    }

    p {
        font-family: 'Montserrat', sans-serif !important;

    }
